<template>
    <cr-confirmation-dialog v-model="showDialog" :title="t('EXERCISE_COPY_EXERCISE_DIALOG_TITLE')" form-instruction @confirm="onConfirmed" @cancel="onCanceled" :disabled="isLoading" data-testing="copy-exercise-dialog">
        <p v-for="text in bodyTexts">{{ text }}</p>
        <template v-if="allowOverwrite">
            <cr-select :label="t('EXERCISE_COPY_EXERCISE_DIALOG_COPY_DESTINATION_LABEL')" v-model="copyDestination" :items="CopyDestinationOptions" :disabled="isLoading" />
            <cr-select v-if="copyDestination === CopyDestination.Overwrite && catalogEntry" :label="t('EXERCISE_COPY_EXERCISE_DIALOG_EXISTING_ENTRY_LABEL')" itemValue="name" :modelValue="existingEntryOptions[0].name" :items="existingEntryOptions" :disabled="isLoading" required />
        </template>
        <cr-text-field v-if="!allowOverwrite || copyDestination === CopyDestination.NewEntry" v-model="name" :label="t('EXERCISE_COPY_EXERCISE_DIALOG_NAME_LABEL')" required :max-length="264" :disabled="isLoading" />
    </cr-confirmation-dialog>
</template>

<script lang="ts">
enum CopyDestination {
    Overwrite = "overwrite",
    NewEntry = "new-entry",
}
</script>

<script lang="ts" setup>
import { computed, ref, shallowRef, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useCatalogStore } from '../../../stores/catalogStore';
import { useSessionStore } from '../../../stores/sessionStore';
import { ICatalogEntry } from '@cyber-range/cyber-range-api-catalog-client';
import Config from '../../../config';

const { t } = useI18n();

const props = defineProps<{
    modelValue: boolean
    catalogId?: string
}>();
const emit = defineEmits<{
    (name: 'update:modelValue', value: boolean): void
    (name: 'confirm', payload: { name?: string, overwrite?: boolean }): void
    (name: 'cancel'): void
}>()

const CopyDestinationOptions = Config.FEATURE_SNAPSHOT_MANAGEMENT ? [
    { id: CopyDestination.Overwrite, name: t('EXERCISE_COPY_EXERCISE_DIALOG_COPY_DESTINATION_OPTION_OVERWRITE') },
    { id: CopyDestination.NewEntry, name: t('EXERCISE_COPY_EXERCISE_DIALOG_COPY_DESTINATION_OPTION_NEW_ENTRY') },
] : []
const copyDestination = ref<CopyDestination>()
const existingEntryOptions = shallowRef<{ name: string }[]>([])
const catalogEntry = shallowRef<ICatalogEntry>();
const allowOverwrite = ref(false);
const isLoading = ref(false);
if (Config.FEATURE_SNAPSHOT_MANAGEMENT)
{
    watch(() => [props.catalogId, props.modelValue], async () => {
        if (!props.modelValue || !props?.catalogId) return

        isLoading.value = true;
        catalogEntry.value = await useCatalogStore().fetch(props?.catalogId);
        const userId = useSessionStore().identity?.id;

        if (!!catalogEntry.value?.userId && catalogEntry.value.userId === userId)
        {
            const familyId = catalogEntry.value.familyIds?.[0]
            const catalogFamily = await useCatalogStore().getCatalogFamily(familyId);
            allowOverwrite.value = true;
            copyDestination.value = CopyDestination.Overwrite;
            existingEntryOptions.value = [{ name: `${catalogFamily?.name} (${catalogFamily?.version})` }]
        }
        else
        {
            allowOverwrite.value = false;
            copyDestination.value = CopyDestination.NewEntry;
        }
        isLoading.value = false;
    }, { immediate: true })
}

const showDialog = computed({
    get: () => props.modelValue,
    set:(value: boolean) => emit('update:modelValue', value)
})
const name = ref('')

const bodyTexts = t('EXERCISE_COPY_EXERCISE_DIALOG_BODY').split('\n')

const onConfirmed = () => emit('confirm', copyDestination.value === CopyDestination.Overwrite  ? { overwrite: true } : { name: name.value });

function onCanceled()
{
    name.value = '';
    emit('cancel');
}
</script>
