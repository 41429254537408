export default {
    en: {
        // Exercise Controls - Mine
        EXERCISE_GROUP_START_MY_EXERCISE_BUTTON: 'Start My Environment',
        EXERCISE_GROUP_STOP_MY_EXERCISE_BUTTON: 'Stop My Environment',
        EXERCISE_GROUP_RESTART_MY_EXERCISE_BUTTON: 'Restart My Environment',
        EXERCISE_GROUP_RESET_MY_EXERCISE_BUTTON: 'Reinstall My Environment',
        EXERCISE_GROUP_COPY_MY_EXERCISE_BUTTON: 'Copy My Environment',
        EXERCISE_GROUP_JOIN_MY_EXERCISE_BUTTON: 'Join My Environment',
        EXERCISE_GROUP_MY_EXERCISE_CREDENTIALS_BUTTON: 'View My Environment Login',
        // Exercise Controls
        EXERCISE_START_BUTTON_TITLE: 'Start',
        EXERCISE_START_BUTTON_LABEL: 'Start, { name }',
        EXERCISE_STOP_BUTTON_TITLE: 'Stop',
        EXERCISE_STOP_BUTTON_LABEL: 'Stop, { name }',
        EXERCISE_RESTART_BUTTON_TITLE: 'Restart',
        EXERCISE_RESTART_BUTTON_LABEL: 'Restart, { name }',
        EXERCISE_RESET_BUTTON_TITLE: 'Reinstall environment',
        EXERCISE_RESET_BUTTON_LABEL: 'Reinstall environment, { name }',
        EXERCISE_COPY_BUTTON_TITLE: 'Copy',
        EXERCISE_COPY_BUTTON_LABEL: 'Copy, { name }',
        EXERCISE_JOIN_BUTTON_TITLE: 'Join',
        EXERCISE_JOIN_BUTTON_LABEL: 'Join, { name }',
        EXERCISE_POPUP_BLOCKER_MESSAGE: "Joining this exercise is blocked by a pop-up blocker.  Please disable popup blockers and re-join the exercise.",
        EXERCISE_CREDENTIALS_BUTTON_TITLE: 'View Credentials',
        EXERCISE_CREDENTIALS_BUTTON_LABEL: 'View Credentials, { name }',
        // Copy Exercise Dialog
        EXERCISE_COPY_EXERCISE_DIALOG_TITLE: 'Are you sure you want to copy this exercise?',
        EXERCISE_COPY_EXERCISE_DIALOG_BODY: "This will create a copy of this user's exercise as a catalog entry, which can then be usable in any of your courses.\nThe exercise will be unavailable during the copy phase, and the catalog creation will take an additional 10-20 minutes.",
        EXERCISE_COPY_EXERCISE_DIALOG_NAME_LABEL: 'Name',
        EXERCISE_COPY_EXERCISE_DIALOG_COPY_DESTINATION_LABEL: "Copy Destination",
        EXERCISE_COPY_EXERCISE_DIALOG_COPY_DESTINATION_OPTION_OVERWRITE: "Create a New Copy and Overwrite an Existing Entry",
        EXERCISE_COPY_EXERCISE_DIALOG_COPY_DESTINATION_OPTION_NEW_ENTRY: "Create a Copy as a New Entry",
        EXERCISE_COPY_EXERCISE_DIALOG_EXISTING_ENTRY_LABEL: "Existing Entry",
        EXERCISE_GROUP_COPYING_TITLE: 'Copy Initiated',
        EXERCISE_GROUP_COPYING_MESSAGE: 'Your environment is being copied and will be available under "My Saved Environments" when creating a new exercise environment.',
        // Reset Exercise Dialog
        EXERCISE_RESET_EXERCISE_CONFIRM_DIALOG_TITLE: 'Are you sure you want to reset this exercise?',
        EXERCISE_RESET_EXERCISE_CONFIRM_DIALOG_TEXT: 'This will delete any work done on this virtual environment and reset it to its original state. It should only be taken if the environment has been somehow corrupted and cannot be repaired otherwise. This action is not reversible.',
        // Exercise Credentials Dialog
        EXERCISE_CREDENTIALS_DIALOG_TITLE: 'Environment Credentials',
        EXERCISE_CREDENTIALS_DIALOG_CONFIRM_TEXT: 'Close',
        EXERCISE_CREDENTIALS_DIALOG_USERNAME: 'Username',
        EXERCISE_CREDENTIALS_DIALOG_COPY_USERNAME: 'Copy username for {name} to clipboard',
        EXERCISE_CREDENTIALS_DIALOG_PASSWORD: 'Password',
        EXERCISE_CREDENTIALS_DIALOG_COPY_PASSWORD: 'Copy password for {name} to clipboard',
        // Exercise Table Headers
        EXERCISE_TEAM_NAME: 'Team Name',
        EXERCISE_CONTROLS: 'Controls',
        EXERCISE_STATUS: 'Status',
        EXERCISE_AVAILABILITY: 'Availability',
        EXERCISE_ACTIVITY: 'Activity',
        // Activity
        EXERCISE_IN_PROGRESS: "In progress since {timestamp}",
        EXERCISE_LAST_ACCESSED: "{duration} on {date}",
        EXERCISE_LAST_ACCESSED_BETWEEN: "{duration} between {from} and {to}",
        // Hints
        EXERCISE_CONTROLS_START_HINT: "Start your environment using",
        EXERCISE_CONTROLS_USE_START_BUTTON_HINT: "the 'Start My Environment' button.",
        EXERCISE_CONTROLS_JOIN_READY_HINT: "Your environment is ready.",
        EXERCISE_CONTROLS_JOIN_ENVIRONMENT_HINT: "Join the environment by using",
        EXERCISE_CONTROLS_USE_JOIN_BUTTON_HINT: "the 'Join My Environment' button.",
        // Non-persistent duration warnings
        EXERCISE_REMAINING_DURATION_THIS_MONTH:  "{remaining} left for this month",
        EXERCISE_REMAINING_DURATION_DEPLETED_MESSAGE: "0 minutes left for this month",
        // expandable exercise activity
        EXERCISE_DETAILS_TOGGLE_TOOLTIP: "Toggle exercise details",
        EXERCISE_DETAILS_COURSE_ID_LABEL: "courseId:",
        EXERCISE_DETAILS_TEAM_ID_LABEL: "teamId:",
        EXERCISE_DETAILS_EXERCISE_ID_LABEL: "exerciseId:",
        EXERCISE_DETAILS_RESOURCE_LOCATION_ID_LABEL: "resourceLocationId:",
        EXERCISE_DETAILS_RESOURCE_LOCATION_ACCOUNT_LABEL: "account:",

        // Exercise Group Controls for CTF
        EXERCISE_GROUP_CTF_COPY_ENVIRONMENT_UNAVAILABLE_TOOLTIP: 'This environment cannot be copied because the environment is no longer available.',
        EXERCISE_GROUP_CTF_COPY_ENVIRONMENT_DISABLED_DUE_TO_ENVIRONMENT_ARTIFACT_PERMISSION_TOOLTIP: 'This environment cannot be copied because it contains an environment artifact.',
    }
};
