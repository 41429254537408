<template>
    <cr-confirmation-dialog v-if="showSurvey && survey" :model-value="surveyStore.shouldShowMiniDialog" :title="t('INSTRUCTOR_SURVEY_DIALOG_TITLE')" formInstruction :confirm-text="t('INSTRUCTOR_MINI_SURVEY_SUBMIT')" :cancel-text="cancelText" @confirm="onConfirmClicked" @cancel="onCancelClicked">
        <instructor-survey-expiration-countdown class="cr-mb4"/>
        <instructor-mini-survey-question class="question" v-for="(question, i) in survey.questions" :question="question" v-model:answer="answers[i]" />
    </cr-confirmation-dialog>
    <cr-confirmation-dialog v-else-if="showThankYouDialog" v-model="showThankYouDialog" :title="t('INSTRUCTOR_SURVEY_THANKS_DIALOG_TITLE')" :cancel="false">
        {{ t('INSTRUCTOR_SURVEY_THANKS_DIALOG_BODY') }}
    </cr-confirmation-dialog>
</template>

<style scoped>
.question:not(:last-child) {
    margin-bottom: 2rem;
}
:deep(legend), :deep(.cr-textarea-label) {
    font-size: 1rem;
}
</style>

<script setup lang="ts">
import { ISurvey } from '@cyber-range/cyber-range-api-survey-client';
import { ISubmissionResponseAnswer } from '@cyber-range/cyber-range-api-survey-client/dist/interfaces/iSubmissionResponseAnswer';
import { computed, ref, shallowReactive, shallowRef, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useInstructorSurveyStore } from '../../stores/instructorSurveyStore';
import InstructorMiniSurveyQuestion from './InstructorMiniSurveyQuestions/InstructorMiniSurveyQuestion.vue';
import InstructorSurveyExpirationCountdown from './InstructorSurveyExpirationCountdown.vue';
import Config from '../../config';

const { t } = useI18n()

const survey = shallowRef<ISurvey>();
const answers = shallowReactive<ISubmissionResponseAnswer[]>([]);
const surveyStore = useInstructorSurveyStore();
const showSurvey = computed(() => surveyStore.shouldShowMiniSurvey && !surveyStore.hasTakenMiniSurvey)
const cancelText = surveyStore.surveyDaysLeft <= Config.INSTRUCTOR_SURVEY_LAST_CHANCE_THRESHOLD_IN_DAYS
                        ? t('INSTRUCTOR_SURVEY_NO_THANKS')
                        : t('INSTRUCTOR_SURVEY_REMIND_ME_LATER')

const showThankYouDialog = ref(false);

watch(showSurvey, async () =>
{
    if (showSurvey.value)
    {
        survey.value = await surveyStore.getSurvey();
    }
}, { immediate: true });

async function onConfirmClicked()
{
    await surveyStore.submitResponse({
        responses: survey.value?.questions
            .map((question, i) => ({
                questionId: question.id,
                answers: [answers[i]]
            }))
            .filter((r) => !!r.answers[0]) || []
    });
    showThankYouDialog.value = true
}
function onCancelClicked()
{
    surveyStore.dismissMiniSurvey();
}

watch(showSurvey, () =>
{
    if (showSurvey.value && surveyStore.shouldShowMiniDialog)
    {
        surveyStore.trackDialogShow();
    }
}, { immediate: true });
</script>
