<template>
    <cr-alert v-if="showSurvey || showMiniSurvey" info condensed :label="t('INSTRUCTOR_SURVEY_ALERT_LABEL')">
        <a v-if="showSurvey" class="cr-ml4" :href="surveyStore.url" target="_blank" @click="onLinkClicked">{{ t('INSTRUCTOR_SURVEY_LINK_TEXT') }}</a>
        <cr-button v-else-if="showMiniSurvey" class="cr-ml2" text @click="onShowMiniSurveyClicked">{{ t('INSTRUCTOR_SURVEY_LINK_TEXT') }}</cr-button>
    </cr-alert>
</template>

<style scoped>
:deep(.cr-alert-label) {
    padding-left: .5rem !important;
}
</style>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useInstructorSurveyStore } from '../../stores/instructorSurveyStore';
import { computed } from 'vue';
const { t } = useI18n()

const surveyStore = useInstructorSurveyStore();
const showSurvey = computed(() => surveyStore.shouldShowSurvey && !surveyStore.hasTakenSurvey)
const showMiniSurvey = computed(() => surveyStore.shouldShowMiniSurvey && !surveyStore.hasTakenMiniSurvey)
const onLinkClicked = surveyStore.dismissPermanently
const onShowMiniSurveyClicked = () => surveyStore.showMiniDialog();
</script>
