import { InternalCustomAttributeValue } from "@cyber-range/cyber-range-api-organization-client";

const isVacr = new RegExp(/virginia|localhost|vacr/,'ig').test(window.location.href);

export default class Config 
{
    public static LOCAL_STORAGE_VERSION_NUMBER = "1.00";
    public static IS_VACR = new RegExp(/virginia|localhost|vacr/,'ig').test(window.location.href);
    public static IS_USCR = !isVacr;

    public static HOMEPAGE_BASE_URL = 'https://virginiacyberrange.org';
    public static PRICING_PAGE_URL = 'https://www.uscyberrange.org/pricing/'; // No pricing page in VACR

    // Apis
    public static CONSOLE_UI_BASE_URL = 'http://console.vacr.io:8076'
    public static LOGIN_UI_BASE_URL = 'http://login.vacr.io:8078';
    public static HOMEPAGE_UI_BASE_URL = 'http://host.uscr.org:8079'
    public static COURSEWARE_UI_BASE_URL = 'http://host.uscr.org:8079'
    public static PORTAL_UI_BASE_URL = 'http://host.vacr.net:8080';
    public static AUTHENTICATION_API_BASE_URL = 'http://localhost:8081/';
    public static ENTITLEMENT_API_BASE_URL = 'http://localhost:8082/';
    public static USER_API_BASE_URL = 'http://localhost:8083/';
    public static CATALOG_API_BASE_URL = 'http://localhost:8084/';
    public static CATALOG_FAMILY_API_BASE_URL = this.CATALOG_API_BASE_URL;
    public static CATALOG_FAMILY_SEARCH_API_BASE_URL = 'http://localhost:8092/';
    public static EXERCISE_API_BASE_URL = 'http://localhost:8085/';
    public static GUACAMOLE_BASE_URL = 'http://localhost:8085/';
    public static INVITATION_API_BASE_URL = 'http://localhost:8086/';
    public static ORGANIZATION_API_BASE_URL = 'http://localhost:8087/';
    public static SUBSCRIPTION_API_BASE_URL = 'http://localhost:8088/';
    public static PRODUCT_API_BASE_URL = 'http://localhost:8088/';
    public static USAGE_API_BASE_URL = 'http://localhost:8089/';
    public static INVOICE_API_BASE_URL = 'http://localhost:8090/';
    public static SEARCH_API_BASE_URL = 'http://localhost:8092/';
    public static AGREEMENT_API_BASE_URL = 'http://localhost:8093';
    public static BUSINESS_UNIT_API_BASE_URL = 'http://localhost:8094';
    public static FILE_API_BASE_URL = 'http://localhost:8097';
    public static SURVEY_API_BASE_URL = 'http://localhost:8098';
    public static NEWSLETTER_API_BASE_URL = 'http://localhost:8099/';
    public static COMPETITION_API_BASE_URL = 'http://localhost:8100';
    public static CREDIT_API_BASE_URL = 'http://localhost:8106'
    public static JOB_API_BASE_URL = 'http://localhost:8109';
    public static AUDIT_API_BASE_URL = 'http://localhost:8110';
    public static SYNC_API_BASE_URL = 'http://localhost:8111';
    public static PAYMENT_API_BASE_URL = 'http://localhost:8112';
    public static BALANCE_API_BASE_URL = 'http://localhost:8113';
    public static CREDENTIAL_API_BASE_URL = 'http://localhost:8116';
    public static KB_BASE_URL = isVacr ? 'https://kb.virginiacyberrange.org' : 'https://kb.uscyberrange.org';
    public static USCR_BASE_URL = 'http://www.uscyberrange.org';
    public static STATUS_BASE_URL = isVacr ? 'https://status.virginiacyberrange.org/' : 'https://status.uscyberrange.org/';
    public static MATOMO_BASE_URL = 'https://analytics.virginiacyberrange.org';
    public static MATOMO_SITE_ID = '5';
    public static DEFAULT_LOGO_URL = isVacr ? '/vacr.svg' : '/uscr.svg';
    public static APP_TITLE = 'Cyber Range';
    public static COURSE_MAX_END_TIME_SETTING = InternalCustomAttributeValue.Date;
    public static GOOGLE_CLASSROOM_URL = "https://classroom.googleapis.com";
    public static GOOGLE_CLIENT_ID = "95079287546-69ftcpsj62ms37i7guuoaiqsdst0d3bs.apps.googleusercontent.com";
    public static GOOGLE_CLASSROOM_DEFAULT_PAGE_SIZE = 25;
    public static DEFAULT_TILE_LISTING_PAGE_SIZE = 12;
    public static DEFAULT_UNIDIRECTIONAL_PAGING_PAGE_SIZE = 10;
    public static DEFAULT_DIALOG_TABLE_PAGE_SIZE = 10;
    public static EXERCISE_MAX_NON_PERSISTENT_ACCESS_DURATION_PER_PERIOD_IN_HOURS = 20;
    public static EXERCISE_MAX_NON_PERSISTENT_ACCESS_DURATION_WARNING_THRESHOLD_IN_HOURS = 5;
    public static EXERCISE_TABLE_PAGE_SIZE = 25;
    public static INVOICE_CONTACT_MAX_BILLING_ADMINS_DISPLAYED = 20;
    public static BALANCE_ACTIVITY_TABLE_PAGE_SIZE = 10;
    public static SAVED_ENVIRONMENT_ACCESS_TABLE_PAGE_SIZE = 10;
    public static DEFAULT_ITEMS_PER_PAGE_OPTIONS = [25, 50, 75, 100];
    public static PROFILE_MEMBERSHIP_REMOVAL_REFRESH_DELAY_IN_MS = 5000;
    public static EXERCISE_GROUP_DEFAULT_START_DATE_OFFSET_FROM_TODAY_IN_DAYS = 14;

    public static FEATURE_CAN_VIEW_NEW_UI_CLAIM_CHECK = true;
    public static FEATURE_SEARCH_ENABLED = true;
    public static FEATURE_SNAPSHOT_MANAGEMENT = true;

    public static INSTRUCTOR_SURVEY_URL = '';
    public static INSTRUCTOR_SURVEY_ID = '';
    public static INSTRUCTOR_SURVEY_START_DATE = '';
    public static INSTRUCTOR_SURVEY_END_DATE = '';
    public static INSTRUCTOR_SURVEY_DAYS_TO_SHOW_COUNTDOWN = 14;
    public static INSTRUCTOR_SURVEY_LAST_CHANCE_THRESHOLD_IN_DAYS = 3;
    public static INSTRUCTOR_SURVEY_DISMISS_DURATION_IN_DAYS = 3;
    public static INSTRUCTOR_SURVEY_DELAY_IN_MS = 30_000;

    public static async load(remotePath: string): Promise<void> 
    {
        const json = await Config.getFile(remotePath);
        Config.init(json);
    }

    public static async getFile(url: string): Promise<object> 
    {
        let response = await fetch(url);
        return response.ok ? await response.json() : {};
    }

    public static init(json: object) {
        Object.assign(Config, json);
    }
}